let settings = {
  dsn: process.env.SENTRY_DSN,
  environment: process.env.CONTEXT, //production, deploy-preview, branch-deploy, dev
  release: process.env.SENTRY_RELEASE_PREFIX + process.env.COMMIT_REF,
};

if (process.env.NETLIFY_DEV === "true") {
  // Local Netlify dev
  settings.environment = "development";
  settings.release = settings.release + "-dev";
} else if (process.env.NETLIFY !== "true") {
  // Local build
  settings.environment = "development";
  settings.release = settings.release + "-build";
} else {
  // Builds for: production, deploy-preview, branch-deploy
}

exports.SentrySettings = {
  ...settings,
};
