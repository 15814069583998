import style from "./style.scss";
import { Fragment } from "preact";
import { useGlobalState } from "../global-state";
import Wsdot from "./wsdot";
import YouTube from "../youtube";
import Popup, { usePopup } from "../popup";

const Webcams = () => {
  const { snowConditions } = useGlobalState();

  const popup = usePopup();

  const openPopup = () => {
    popup.showPopup(
      <Popup>
        <ul class={style.popup} onClick={popup.hidePopup}>
          <li class={style.wsdot}>
            <div>
              <Wsdot />
            </div>
          </li>
          {snowConditions.webcams.map((item, index) => (
            <li class={style.youtube} key={index}>
              <div>
                <YouTube id={item} />
              </div>
            </li>
          ))}
        </ul>
      </Popup>
    );
  };

  return (
    <ul class={style.grid}>
      <li class={style.wsdot}>
        <button onClick={openPopup} title="View Webcams">
          <Wsdot />
        </button>
      </li>

      {snowConditions.webcams.slice(0, 3).map((item, index) => (
        <li class={style.youtube} key={index}>
          <div>
            <YouTube id={item} mini />
          </div>
        </li>
      ))}

      {snowConditions.webcams.length >= 3 ? null : (
        <Fragment>
          {[...Array(3 - snowConditions.webcams.length)].map((item, index) => (
            <li class={style.youtube} key={index}>
              <div>b</div>
            </li>
          ))}
        </Fragment>
      )}
    </ul>
  );
};

export default Webcams;
