import { useContext } from "preact/hooks";
import style from "./style.scss";
import { PopupContext } from "./context";

export const usePopup = () => useContext(PopupContext);

export const PopupRoot = () => {
  const popup = usePopup();

  return popup.open ? popup.content : null;
};

const Popup = ({ children }) => {
  const popup = usePopup();

  const close = () => {
    popup.hidePopup();
  };

  return (
    <div class={style.popup}>
      <button
        type="button"
        title="close"
        onClick={close}
        class={style.closeButton}
      >
        <div />
      </button>
      <div class={style.content}>{children}</div>
    </div>
  );
};

export default Popup;
