import Popup from "../popup";
import style from "./style.scss";

const PopupPage = ({ link }) => {
  return (
    <Popup>
      <iframe
        class={style.frame}
        src={link}
        referrerPolicy="same-origin"
        scrolling="auto"
      />
    </Popup>
  );
};

export default PopupPage;
