import Popup from "../popup";
import style from "./style.scss";

const PopupContent = ({ children }) => {
  return (
    <Popup>
      <div class={style.content}>{children}</div>
    </Popup>
  );
};

export default PopupContent;
