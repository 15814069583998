import { createContext } from "preact";
import { useCallback, useContext, useEffect, useState } from "preact/hooks";

const cacheMode =
  process.env.API_FORCE_CACHE === "true" ? "force-cache" : "no-cache";

export const GlobalStateContext = createContext({
  refresh: () => {},
  lastRefresh: null,
  refreshingWsdot: null,
  refreshingSummit: null,
  refreshingStations: null,
  roadConditions: null,
  snowConditions: null,
  stations: null,
  webcams: {
    // eslint-disable-next-line no-unused-vars
    setIndex: (index) => {},
    currentIndex: null,
    cameras: null,
  },
});

//export const GlobalStateConsumer = GlobalStateContext.Consumer;

export const GlobalStateProvider = ({ children }) => {
  const [lastRefresh, setLastRefresh] = useState(Date.now());

  const [refreshingWsdot, setRefreshingWsdot] = useState(true);

  const [refreshingSummit, setRefreshingSummit] = useState(true);

  const [refreshingStations, setRefreshingStations] = useState(true);

  const [roadConditions, setRoadConditions] = useState({
    eastbound: null,
    westbound: null,
    roadCondition: null,
    weatherCondition: null,
    temperature: null,
    dateUpdated: null,
    travelAdvisoryActive: null,
  });

  const [snowConditions, setSnowConditions] = useState({
    backcountryGates: { elevator: null, nash: null },
    comments: null,
    telemetry: {
      top: {
        temp: null,
        snowfall: { "5am": null, "12hr": null, "24hr": null, "48hr": null },
      },
      mid: {
        temp: null,
        snowfall: { "5am": null, "12hr": null, "24hr": null, "48hr": null },
      },
      base: {
        temp: null,
        snowfall: { "5am": null, "12hr": null, "24hr": null, "48hr": null },
      },
    },
    webcams: ["TQXFBknn-xk", "guN6h5WV3n4", "ZUwO9kPpSi0", "guN6h5WV3n4"],
  });

  const [stations, setStations] = useState({
    top: {
      airTemp: null,
      dateTime: null,
    },
    mid: {
      airTemp: null,
      dateTime: null,
    },
    base: {
      airTemp: null,
      dateTime: null,
    },
  });

  const [webcamIndex, setWebcamIndex] = useState(4);

  useEffect(() => {
    if (refreshingWsdot) {
      fetch("/api/wsdot", {
        cache: cacheMode,
      })
        .then((res) => res.json())
        .then((data) => {
          return setRoadConditions(data);
        })
        .finally(() => {
          setRefreshingWsdot(false);
        });
    }
  }, [refreshingWsdot]);

  useEffect(() => {
    if (refreshingSummit) {
      fetch("/api/summitatsnoqualmie", {
        cache: cacheMode,
      })
        .then((res) => res.json())
        .then((data) => {
          return setSnowConditions(data);
        })
        .finally(() => {
          setRefreshingSummit(false);
        });
    }
  }, [refreshingSummit]);

  useEffect(() => {
    if (refreshingStations) {
      fetch("/api/stations", {
        cache: cacheMode,
      })
        .then((res) => res.json())
        .then((data) => {
          return setStations(data);
        })
        .finally(() => {
          setRefreshingStations(false);
        });
    }
  }, [refreshingStations]);

  return (
    <GlobalStateContext.Provider
      value={{
        refresh: useCallback(() => {
          setLastRefresh(Date.now()); // Also used to refresh webcam images
          setRefreshingWsdot(true);
          setRefreshingSummit(true);
          setRefreshingStations(true);
        }, []),
        lastRefresh,
        refreshingWsdot,
        refreshingSummit,
        refreshingStations,
        roadConditions,
        snowConditions,
        stations,
        webcams: {
          setIndex: setWebcamIndex,
          currentIndex: webcamIndex,
          cameras: [
            // Sorted west to east
            "https://images.wsdot.wa.gov/sc/090VC04526.jpg",
            "https://images.wsdot.wa.gov/sc/090VC04680.jpg",
            "https://images.wsdot.wa.gov/sc/090VC04810.jpg",
            "https://images.wsdot.wa.gov/sc/090VC05130.jpg",
            "https://images.wsdot.wa.gov/sc/090VC05200.jpg", // Default
            "https://images.wsdot.wa.gov/sc/090VC05347.jpg",
            "https://images.wsdot.wa.gov/sc/090VC05517.jpg",
            "https://images.wsdot.wa.gov/sc/090VC05771.jpg",
            "https://images.wsdot.wa.gov/sc/090VC06740.jpg",
            "https://images.wsdot.wa.gov/sc/090VC07060.jpg",
            "https://images.wsdot.wa.gov/sc/090VC07319.jpg",
            "https://images.wsdot.wa.gov/rweather/Medium_bullfrog2.jpg",
          ],
        },
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => {
  return useContext(GlobalStateContext);
};
