import { useEffect } from "preact/hooks";
import { useGlobalState } from "../global-state";
import PullToRefresh from "pulltorefreshjs";
//import render from "preact-render-to-string";

const debugStyles = false;
const PullableRefresh = ({ main }) => {
  const { refresh } = useGlobalState();

  useEffect(() => {
    if (typeof window !== "undefined") {
      PullToRefresh.init({
        mainElement: main,
        onRefresh: refresh,
      });
    }
    return function cleanup() {
      if (PullToRefresh) {
        PullToRefresh.destroyAll();
      }
    };
  }, [main, refresh]);

  return debugStyles ? (
    <div class="ptr--ptr ptr--refresh" style="min-height: 80px;">
      <div class="ptr--box">
        <div class="ptr--content">
          <div class="ptr--icon">…</div>
          <div class="ptr--text">Refreshing</div>
        </div>
      </div>
    </div>
  ) : null;
};

export default PullableRefresh;
