import style from "./style.scss";
import { usePopup } from "../popup";
//import PopupTwitter from "../popup-twitter";
import PopupPage from "../popup-page";
import PopupContent from "../popup-content";
import Emergency from "../emergency";

const LinkList = ({ links }) => {
  const popup = usePopup();

  const openLink = (e, { link, newWindow }) => {
    if (e.ctrlKey || newWindow) {
      return;
    }
    e.preventDefault();
    popup.showPopup(<PopupPage link={link} />);
  };

  const openEmergency = (e) => {
    e.preventDefault();
    popup.showPopup(
      <PopupContent>
        <Emergency />
      </PopupContent>
    );
  };

  return (
    <ul class={style.linkList}>
      <li>
        <a onClick={(e) => openEmergency(e)} rel="noreferrer">
          <img src="/logos/ski-patrol.svg" alt="" />
          Emergency
        </a>
      </li>
      {links.map((item, index) => (
        <li key={index}>
          <a
            href={item.link}
            onClick={(e) => openLink(e, item)}
            target="_blank"
            rel="noreferrer"
          >
            <img src={item.img} alt="" />
            {item.text}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default LinkList;
