import { Fragment } from "preact";
import { useEffect } from "preact/hooks";
import { useGlobalState, GlobalStateProvider } from "./global-state";
import { usePageVisibility } from "../hooks/usePageVisibility";
import PullableRefresh from "../components/pullable-refresh";
import { PopupRoot } from "./popup";
import { PopupProvider } from "./popup/context";
import Layout from "./layout";

const Application = () => {
  const globalState = useGlobalState();
  const isVisible = usePageVisibility();

  useEffect(() => {
    const getMinutesSinceRefresh = () => {
      const milliseconds = Date.now() - globalState.lastRefresh;
      return milliseconds / 60000; // Convert milliseconds to minutes
    };

    if (isVisible && getMinutesSinceRefresh() >= 5) {
      // Refresh if 5 mins has passed and page now became visible
      globalState.refresh();
    }
  }, [isVisible, globalState]);

  return (
    <Fragment>
      <PullableRefresh main="#app" />
      <div id="app">
        <PopupRoot />
        <div>
          <Layout />
        </div>
      </div>
    </Fragment>
  );
};

const App = () => (
  <GlobalStateProvider>
    <PopupProvider>
      <Application />
    </PopupProvider>
  </GlobalStateProvider>
);
export default App;
