import { useSwipeable } from "react-swipeable";
import { useGlobalState } from "../../global-state";
import style from "./style.scss";

const WsdotWebcams = () => {
  const { lastRefresh, webcams } = useGlobalState();

  const onSwipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      // East
      let newIndex = webcams.currentIndex + 1;
      if (newIndex >= webcams.cameras.length) {
        newIndex = 0;
      }
      webcams.setIndex(newIndex);
    },
    onSwipedRight: () => {
      // West
      let newIndex = webcams.currentIndex - 1;
      if (newIndex < 0) {
        newIndex = webcams.cameras.length - 1;
      }
      webcams.setIndex(newIndex);
    },
  });

  const carouselDots = () => (
    <ul class={style.carouselDots}>
      {webcams.cameras.map((item, index) => (
        <li
          key={index}
          class={index === webcams.currentIndex ? style.current : null}
        />
      ))}
    </ul>
  );

  return (
    <div
      ref={onSwipeHandlers.ref}
      onMouseDown={() => onSwipeHandlers.onMouseDown}
    >
      <img
        src={`${webcams.cameras[webcams.currentIndex]}?v=${lastRefresh}`}
        alt="Snoqualmie Pass"
        referrerPolicy="same-origin"
      />
      {carouselDots()}
    </div>
  );
};

export default WsdotWebcams;
