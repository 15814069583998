// https://developers.google.com/youtube/iframe_api_reference

const YouTube = ({ id, mini = false }) =>
  mini ? (
    <iframe
      src={`https://www.youtube.com/embed/${id}?enablejsapi=1&rel=0&modestbranding=1&playsinline=1&origin=`}
      allowFullScreen
    />
  ) : (
    <iframe
      src={`https://www.youtube.com/embed/${id}?enablejsapi=1&rel=0&modestbranding=1&playsinline=1&controls=0&fs=0&autoplay=0`}
      allowFullScreen
    />
  );

export default YouTube;
