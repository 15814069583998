import { useState, useEffect } from "preact/hooks";

// https://blog.sethcorker.com/harnessing-the-page-visibility-api-with-react

const getVisibilityChangeProp = () => {
  if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    return "visibilitychange";
  } else if (typeof document["msHidden"] !== "undefined") {
    return "msvisibilitychange";
  } else if (typeof document["webkitHidden"] !== "undefined") {
    return "webkitvisibilitychange";
  }
};

const getDocumentHiddenProp = () => {
  if (typeof document.hidden !== "undefined") {
    return "hidden";
  } else if (typeof document["msHidden"] !== "undefined") {
    return "msHidden";
  } else if (typeof document["webkitHidden"] !== "undefined") {
    return "webkitHidden";
  }
};

const getIsDocumentHidden = () => {
  return !document[getDocumentHiddenProp()];
};

export const usePageVisibility = () => {
  /* eslint-disable react-hooks/rules-of-hooks */
  if (typeof document === "undefined") {
    return false;
  }

  const [isVisible, setIsVisible] = useState(getIsDocumentHidden());

  const onVisibilityChange = () => setIsVisible(getIsDocumentHidden());

  useEffect(() => {
    const visibilityChange = getVisibilityChangeProp();
    document.addEventListener(visibilityChange, onVisibilityChange, false);
    return () => {
      document.removeEventListener(visibilityChange, onVisibilityChange);
    };
  });

  return isVisible;
  /* eslint-enable react-hooks/rules-of-hooks */
};
