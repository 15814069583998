import { createContext } from "preact";
import { useState } from "preact/hooks";

export const PopupContext = createContext({
  open: false,
  content: null,
  // eslint-disable-next-line no-unused-vars
  showPopup: (content) => {},
  hidePopup: () => {},
});

//export const PopupConsumer = PopupContext.Consumer;

export const PopupProvider = ({ children }) => {
  const [popup, setPopop] = useState({
    open: false,
    content: null,
  });

  const showPopup = (content) => {
    setPopop({
      open: true,
      content,
    });

    if (document) {
      document.body.classList.add("has-popup");
    }
  };

  const hidePopup = () => {
    setPopop({
      open: false,
      content: null,
    });
    if (document) {
      document.body.classList.remove("has-popup");
    }
  };

  return (
    <PopupContext.Provider
      value={{ open: popup.open, content: popup.content, showPopup, hidePopup }}
    >
      {children}
    </PopupContext.Provider>
  );
};
