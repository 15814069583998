import * as Sentry from "@sentry/react";
import { SentrySettings } from "../functions/util/sentry/settings";

if (process.env.SENTRY_ENABLED === "true") {
  Sentry.init({
    ...SentrySettings,
    ...{
      beforeSend(event) {
        // Check if it is an exception, and if so, show the report dialog
        if (event.exception) {
          Sentry.showReportDialog({
            eventId: event.event_id,
            user: {
              name: "Anonymous",
              email: "anonymous@matanich.com",
            },
          });
        }
        return event;
      },
    },
  });
}
