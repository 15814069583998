import style from "./style.scss";
import { useGlobalState } from "../global-state";
import { mergeClasses } from "../helpers";

const closedRegex = /close/i;

const Wsdot = () => {
  const { roadConditions } = useGlobalState();

  const dateUpdated = roadConditions.dateUpdated
    ? new Date(roadConditions.dateUpdated)
    : null;

  return (
    <div class={style.wsdot}>
      <div class={style.temp}>
        <span class={style.value}>{roadConditions.temperature}</span>
      </div>
      <div
        class={mergeClasses([
          style.eastbound,
          closedRegex.test(roadConditions.eastbound) && style.closed,
        ])}
      >
        <span class={style.value}>{roadConditions.eastbound}</span>
      </div>
      <div
        class={mergeClasses([
          style.westbound,
          closedRegex.test(roadConditions.westbound) && style.closed,
        ])}
      >
        <span class={style.value}>{roadConditions.westbound}</span>
      </div>
      <div
        class={mergeClasses([
          style.roadCondition,
          closedRegex.test(roadConditions.roadCondition) && style.closed,
        ])}
      >
        <span class={style.value}>{roadConditions.roadCondition}</span>
      </div>
      {roadConditions.weatherCondition ? (
        <div class={style.weather}>
          <span class={style.value}>{roadConditions.weatherCondition}</span>
        </div>
      ) : null}
      <div class={style.date}>
        <span class={style.value}>
          {dateUpdated
            ? `${dateUpdated.toLocaleDateString("en-US", {
                weekday: "long",
                month: "numeric",
                day: "numeric",
                year: "numeric",
              })} ${dateUpdated.toLocaleTimeString("en-US", {
                hour12: true,
                hour: "numeric",
                minute: "2-digit",
                //second: undefined,
                //timeZoneName: undefined
              })}`
            : null}
        </span>
      </div>
    </div>
  );
};

export default Wsdot;
