import style from "./style.scss";
import { useState } from "preact/hooks";
import { useGlobalState } from "../global-state";
import { mergeClasses } from "../helpers";

const Snowfall = () => {
  const { snowConditions, stations } = useGlobalState();
  const [isCommentExpanded, setIsCommentExpanded] = useState(false);

  const toggleComment = () => setIsCommentExpanded(!isCommentExpanded);

  return (
    <div class={style.skiareaInfo}>
      <div class={style.snowInfoLabels}>
        <h2 />
        <div class={style.temp}>Temp</div>
        <div class={style.snow}>5am</div>
        <div class={style.snow}>12hr</div>
        <div class={style.snow}>24hr</div>
        <div class={style.snow}>48hr</div>
        <div class={style.snow}>Base</div>
      </div>
      <div class={style.snowInfo}>
        <h2>Top</h2>
        <div class={style.temp}>
          <span>
            {snowConditions.telemetry.top.temp ?? stations.top.airTemp}
          </span>
        </div>
        <div class={mergeClasses([style.snow, style.snow5am])}>
          <span>{snowConditions.telemetry.top.snowfall["5am"]}</span>
        </div>
        <div class={style.snow}>
          <span>{snowConditions.telemetry.top.snowfall["12hr"]}</span>
        </div>
        <div class={style.snow}>
          <span>{snowConditions.telemetry.top.snowfall["24hr"]}</span>
        </div>
        <div class={style.snow}>
          <span>{snowConditions.telemetry.top.snowfall["48hr"]}</span>
        </div>
        <div class={style.snow}>
          <span>{snowConditions.telemetry.top.snowfall["base"]}</span>
        </div>
      </div>
      <div class={style.snowInfo}>
        <h2>Mid</h2>
        <div class={style.temp}>
          <span>
            {snowConditions.telemetry.mid.temp ?? stations.mid.airTemp}
          </span>
        </div>
        <div class={mergeClasses([style.snow, style.snow5am])}>
          <span>{snowConditions.telemetry.mid.snowfall["5am"]}</span>
        </div>
        <div class={style.snow}>
          <span>{snowConditions.telemetry.mid.snowfall["12hr"]}</span>
        </div>
        <div class={style.snow}>
          <span>{snowConditions.telemetry.mid.snowfall["24hr"]}</span>
        </div>
        <div class={style.snow}>
          <span>{snowConditions.telemetry.mid.snowfall["48hr"]}</span>
        </div>
        <div class={style.snow}>
          <span>{snowConditions.telemetry.mid.snowfall["base"]}</span>
        </div>
      </div>
      <div class={style.snowInfo}>
        <h2>Base</h2>
        <div class={style.temp}>
          <span>
            {snowConditions.telemetry.base.temp ?? stations.base.airTemp}
          </span>
        </div>
        <div class={mergeClasses([style.snow, style.snow5am])}>
          <span>{snowConditions.telemetry.base.snowfall["5am"]}</span>
        </div>
        <div class={style.snow}>
          <span>{snowConditions.telemetry.base.snowfall["12hr"]}</span>
        </div>
        <div class={style.snow}>
          <span>{snowConditions.telemetry.base.snowfall["24hr"]}</span>
        </div>
        <div class={style.snow}>
          <span>{snowConditions.telemetry.base.snowfall["48hr"]}</span>
        </div>
        <div class={style.snow}>
          <span>{snowConditions.telemetry.base.snowfall["base"]}</span>
        </div>
      </div>

      <ul class={style.bcGates}>
        <li
          class={
            snowConditions.backcountryGates.nash === "open"
              ? style.open
              : snowConditions.backcountryGates.nash === "closed"
              ? style.closed
              : null
          }
        >
          <span class={style.label}>Nash Gate</span>
          <span class={style.value} />
        </li>
        <li
          class={
            snowConditions.backcountryGates.elevator === "open"
              ? style.open
              : snowConditions.backcountryGates.elevator === "closed"
              ? style.closed
              : null
          }
        >
          <span class={style.label}>Elevator Gate</span>
          <span class={style.value} />
        </li>
      </ul>

      {snowConditions.comments ? (
        <div
          class={mergeClasses([
            style.snowComments,
            isCommentExpanded ? style.show : null,
          ])}
          onClick={toggleComment}
        >
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: snowConditions.comments,
            }}
          />
        </div>
      ) : null}

      <div class={style.date}>
        <span>
          {snowConditions.telemetry.lastUpdated ==
          snowConditions.backcountryGates.lastUpdated
            ? snowConditions.telemetry.lastUpdated
            : `${snowConditions.telemetry.lastUpdated} / ${snowConditions.backcountryGates.lastUpdated}`}
        </span>
      </div>
    </div>
  );
};

export default Snowfall;
